<template>
    <b-card :style="`min-height: ${screenHeight}`">
      <transition name="overlay-fade"><div class="overlay" v-if="showOverlay"></div></transition>
      <div>
          <span class="color-title-company">Info de la empresa</span>
      </div>
      <hr class="mt-50">
      <div class="container_title_company--freemium d-flex align-items-center justify-content-start mt-4 mb-2 pr-2 pl-2">
        <div class="d-flex align-items-center">
            <h1 class="title-section-info">Datos de la empresa</h1>
        </div>
      </div>
      <div v-if="loading.companyInfo" class="row px-3 pt-1 skeleton_company--freemium">
          <b-col cols="3">
            <b-skeleton-img class="mt-1" no-aspect height="300px"></b-skeleton-img>
          </b-col>
          <b-col cols="9">
            <b-skeleton class="mt-1" type="input"></b-skeleton>
            <b-skeleton class="mt-1" type="input"></b-skeleton>
            <b-skeleton class="mt-1" type="input"></b-skeleton>
            <b-skeleton class="mt-1" type="input"></b-skeleton>
            <b-skeleton class="mt-1" type="input"></b-skeleton>
          </b-col>
          <b-col cols="12">
            <b-skeleton class="mt-1" type="input"></b-skeleton>
          </b-col>
      </div>
      <div v-else>
        <div class="d-flex px-3 pt-1 container_company_freemium">
            <div class="file-drop-area mr-4">
                <div class="container_company--info">
                    <b-dropdown size="lg" variant="link" toggle-class="text-decoration-none" no-caret  @shown="onDropdownOpen" @hidden="onDropdownClose">
                        <template #button-content>
                        <div :class="isOpenDropdown ? 'container_company--button' : ''">
                            <img class="container_company--logo" :src="form.logo_url_v2 || IconLogoDefault" alt="Logo de la empresa" />
                        </div>
                        </template>
                        <b-dropdown-item @click="openFileInput">Cargar logo</b-dropdown-item>
                    </b-dropdown>
                </div>
                <p class="mb-1 pt-1 text-center"><small>Imagen (PNG) Tamaño sugerido 300px por 300px</small></p>
                <input
                    ref="fileInput"
                    v-if="!loading.logo"
                    type="file"
                    @change="changeCompanyLogo"
                    name="file-input-company"
                    id="file-input-company"
                    class="file-input invisible d-none"
                    accept=".png, jpg"
                />
            </div>
            <div class="w-100 pr-1 container_company_freemium--form">
                <form-render
                    ref="formRenderCompanyFreemium"
                    @send="handleSubmitEdit"
                    :key="keyFormRender" 
                    class="px-0" 
                    :fields="fields" 
                    :form.sync="form"
                    containerButtonsClass="col-md-4 col-xl-3 mt-2" 
                />
            </div>
        </div>
        <div class="w-full container_btn_company">
          <div class="col-xs-12 pr-3 container_btn_company--saved">
            <b-button @click="validateForm" :disabled="loading.disabledBtnSaved" variant="warning" class="push-right">
              <span v-if="!loading.savedChangeCompany" class="mb-1">Guardar cambios</span>
              <span class="px-2" v-else ><b-spinner small /> </span>
            </b-button>
          </div>
        </div>
      </div>
    </b-card>
</template>
<script>
import { mapGetters } from 'vuex'
import defaultLogo from '@/assets/images/icons/company_default_grey.png'
import BaseServices from '@/store/services/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
  name: 'companyInfoFreemium',
  data () {
    return {
      company_id: this.$route.params?.id,
      loading: {
        companyInfo: true,
        disabledBtnSaved: true,
        savedChangeCompany: false
      },
      form: {},
      fields: [],
      keyFormRender: 0,
      isOpenDropdown: false,
      IconLogoDefault: defaultLogo,
      company_data: null,
      baseService: new BaseServices(this),
      showOverlay: false,
      screenHeight: `${screen.height - 270}px`
    }
  },
  watch: {
    mySession () {
      this.handleSessionVerification()
    },
    companyInfo(value) {
      if (!!value) {
        this.company_data = value.data
        this.setInitialData()
      }
      // Para que no se llame cada vez que se realice una edición en la empresa
      if (!this.mySession?.id) {
        this.handleSessionVerification()
      }
    },
    generalLoading: {
      handler () {
        this.loading.companyInfo = !!this.generalLoading.getCompanyInformation
      }
    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession',
      companyInfo: 'getCompanyInformation',
      generalLoading: 'getLoading'
    })
  },
  mounted () {
    this.handleSessionVerification()
    this.getCompanyInfo()
  },
  methods: {
    handleSessionVerification() {
      this.verifySession()
    },
    // Por temas de cargar mas rapido el modulo se llena el formulario a la respuesta del endpoint
    // pero para asegurarnos que ya existe la sesión se utiliza esta función que activa el boton de guardar cambios
    verifySession() {
      if (!!this.mySession?.id && !!this.company_data) {
        this.loading.disabledBtnSaved = false
      } else {
        setTimeout(() => {
          this.handleSessionVerification()
        }, 200)
      }
    },
    getCompanyInfo() {
      this.$store.dispatch('fetchService', { name: 'getCompanyInformation', params: {company_id: this.company_id} })
    },
    setInitialData () {
      this.fields = [
        {fieldType: 'FieldSelect', name: 'country', label: 'País', containerClass: 'col-md-4 container-info', addFlags: true, validation: 'required', disabled: true},
        {fieldType: 'FieldInput', name: 'name2', label: 'Razón Social', containerClass: 'col-md-4 container-info', placeholder: 'Ingrese rázon social', validation: 'required', disabled: true},
        {fieldType: 'FieldInput', name: 'dni', label: 'WORD_DNI', containerClass: 'col-md-4 container-info', placeholder: 'Ingrese rut', validation: 'required', disabled: true},
        {fieldType: 'FieldInput', name: 'name1', label: 'Nombre', containerClass: 'col-md-4 container-info mt-50', placeholder: 'Ingrese nombre', validation: 'required'},
        {fieldType: 'FieldInput', name: 'id', label: 'ID', containerClass: 'col-md-4 container-info mt-50', placeholder: 'Ingrese ID', validation: 'required', disabled: true},
        {fieldType: 'FieldInput', name: 'webpage', label: 'Sitio web', containerClass: 'col-md-12 container-info mt-50', placeholder: 'Ingrese url'},
        { fieldType: 'FieldSelect', name: 'place', label: 'Ubicación (Comuna, Colonia, Municipio ó Distrito)', validation: 'required', containerClass: 'col-md-6 container-info mt-50', clearable: true, searchOnType: { fx: this.setPlaces, nChars: 3, debounce: 600, persistSearch: true }},
        {fieldType: 'FieldInput', name: 'full_address', label: 'Dirección', containerClass: 'col-md-4 container-info mt-50', placeholder: 'Ingrese nombre', validation: 'required'}
      ]
      this.setForm()
    },
    setForm () {
      if (!!this.company_data) {
        const indexCountry = this.company_data.countries.findIndex((el) => el.id === this.company_data.company.country_id)
        this.form = {
          ...this.company_data.company,
          activated: this.company_data.company.activated === 1,
          show_cyber_form: this.company_data.company.show_cyber_form === 1,
          country: {
            ...this.company_data.countries[indexCountry],
            text: this.company_data.countries[indexCountry].name
          },
          contact_email: this.companyInfo.data.company.billing_info?.contact_email, 
          contact_phone: this.companyInfo.data.company.billing_info?.contact_phone,
          full_address: this.companyInfo.data.company.address?.full_address
        }
        if (this.form.address?.place?.name && this.form.address?.place?.id) {
          this.setPlaces(this.form.address.place?.name, this.form.address?.place?.id)
        }
      }
    },
    async setPlaces(value, selection) {
      try {
        const params = { country_id: this.form.country.id }
        const queryParams = { str: value }
        const resp = await this.baseService.callService('getFullPlaces', queryParams, params)
        const response = resp.places.map(el => ({...el, text: el.full_name, valid: true}))
        if (selection !== '' && selection !== undefined && selection !== null) {
          const newPlace = response.find(el => el.id === selection)
          this.form.place = newPlace
          this.keyFormRender++
        }
        return response
      } catch (err) {
        return err
      }
    },
    validateForm () {
      this.$refs.formRenderCompanyFreemium.checkForm()
    },
    handleSubmitEdit (data) {
      this.loading.disabledBtnSaved = true
      this.loading.savedChangeCompany = true
      const payload = {
        id: this.form.id,
        name1: data.name1,
        name2: this.form.name2,
        code: this.form.code,
        country: data.country,
        country_id: data.country.id,
        address: {
          full_address: this.form.full_address,
          place: this.form.place,
          place_id: this.form.place?.id ? this.form.place.id : null,
          place_name: this.form.place?.name,
          zip_code: this.form.zip_code || null
        },
        authority_contacts: [{email: '', full_name: ''}],
        webpage: data.webpage,
        dni: this.form.dni
      }
      this.showOverlay = true
      this.baseService.callService('putEditCompany', payload, {company_id: this.form.id})
        .then(resp => {
          this.getCompanyInfo()
          this.keyFormRender++
          this.$newSuccess('Excelente', 'La empresa se ha actualizado correctamente')
        })
        .catch(err => {
          const dataToastNotification = {
            title: 'Oops',
            message: `La empresa no se ha actualizado. Error: ${err.message}`,
            variant: 'danger',
            icon: 'warning'
          }
          this.showToastNotificationError(dataToastNotification)
        })
        .finally(() => {
          this.keyFormRender++
          this.loading.disabledBtnSaved = false
          this.loading.savedChangeCompany = false
          this.showOverlay = false
        })
    },
    onDropdownOpen() {
      this.isOpenDropdown = true
    },
    onDropdownClose() {
      this.isOpenDropdown = false
    },
    openFileInput () {
      this.$refs.fileInput.click()
    },
    openModalLogo () {
      this.$bvModal.show('modalLogoCompany')
    },
    changeCompanyLogo (event) {
      const file = event.target.files[0]
      const data = {
        name: 'changeLogo',
        params: {company_id: this.form.id},
        data: { logo: file, type: 'profile' }
      }
      this.showOverlay = true
      this.baseService
        .callUploadFileCustomParams(data.name, data.data, data.params)
        .then((response) => {
          this.form.logo_url_v2 = response.data.company.logo_url_v2
          this.keyFormRender++
          this.$newSuccess('Excelente', 'El logo se ha actualizado correctamente')
        })
        .catch((err) => {
          const dataToastNotification = {
            title: 'Oops',
            message: `El logo no se ha actualizado Error: ${err.message}`,
            variant: 'danger',
            icon: 'warning'
          }
          this.showToastNotificationError(dataToastNotification)
        })
        .finally(() => {
          this.showOverlay = false
          this.loading.logo = false
        })
    },
    showToastNotificationError(dataToastNotification) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: dataToastNotification.title,
          icon: dataToastNotification.icon,
          variant: dataToastNotification.variant,
          text: dataToastNotification.message
        }
      })
    }
  }

}
</script>

<style lang='scss'>
    .loading-spinner-company {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgb(77 77 77 / 70%);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 40000
    }
    .custom-spinner {
        color: #fff;
        width: 10rem; 
        height: 10rem;
    }
    .collapse-company {
        .card-header {
        justify-content: flex-start;
        }

        [data-toggle='collapse'] {
        &:after {
            position: initial;
            margin-top: 0;
            margin-left: 0.5rem;
        }

        }
    }
    .hide {
        display: none;
    }
    .color-title-company {
      color: #044389;
    }
    .container_company--info {
      width: 300px;
      height: 302px;
    }
    .container_company--logo {
      height: 302px;
      width: 300px;
      border: 2px dotted #ccc;
      max-height: 302px;
      max-width: 300px;
      border-radius: 10px;
    }
    .container_company--info button {
      padding: 0;
      border: none;
    }
    .container_company--info ul {
      position: absolute;
      will-change: transform !important;
      top: 200px !important;
      left: 210px !important;
      transform: none !important;
    }
    .checkbox-company .custom-control-label {
      margin-bottom: 0 !important;
    }
    .title-section-info{
      font-size: 20px;
      font-weight: bold;
      margin-right: 1.5rem;
      margin-bottom: 0;
    }
    .container_company--button {
      position: relative;
    }
    .container_company--button::before {
    content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 10px;
      z-index: 2;
    }
    .api_key_company {
      padding: 0;
      align-self: center;
      align-items: center;
      margin-bottom: 0;
      margin-top: 16px;
    }
    .api_key_company button {
      padding: 0;
      margin: 0;
    }
    @media (max-width: 768px) {
      .skeleton_company--freemium div {
        max-width: 100%;
        flex: auto;
      }
      .skeleton_company--freemium div .b-skeleton-img{
        height: 150px !important;
      }
      .container_company_freemium {
        flex-direction: column;
      }
      .container_title_company--freemium {
        justify-content: center !important;
      }
      .container_company_freemium .file-drop-area {
        margin: 0 !important;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .container_company--info {
        width: 150px;
        height: 152px;
      }
      .container_company--logo {
        height: 152px;
        width: 150px;
        max-height: 152px;
        max-width: 150px;
      }
      .container_company--api-key {
        padding: 0 3rem;
        margin-bottom: 1rem;
      }
      .api_key_company {
        padding: inherit;
        display: flex;
        justify-content: center;
      }
      .container_btn_company{
        display: flex;
        justify-content: center;
        
      }
      .container_btn_company .container_btn_company--saved, .container_company_freemium--form {
        padding: 0 !important;
      }
      .title-section-info {
        margin: 0;
      }
      .container_company--info ul {
        top: 80px !important;
        left: 100px !important;
      }
    }
</style>